import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import { stripTrailingSlash } from 'utils/functions';
import languageConfig from 'locales/config.json';
import { LangIndex } from '../../../config';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as styles from './lang-slug.module.scss';
import clsx from 'clsx';

type Model =
  | 'start_page'
  | 'page'
  | 'contact_page'
  | 'news'
  | 'news_archive'
  | 'news_category'
  | 'project'
  | 'project_archive'
  | 'project_category';

type LangSlugProps = {
  langList?: GatsbyTypes.LangSlugFragment[];
  model: Model;
};

const LangSlug: React.FC<LangSlugProps> = ({ langList = [], model }) => {
  const { i18n } = useTranslation();
  const staticPage: Model[] = ['contact_page', 'start_page', 'news_archive', 'project_archive'];
  const [visible, setVisible] = React.useState<boolean>(true);

  const checkPosition = React.useCallback(() => {
    if (window.scrollY >= 150 && visible) setVisible(false);
    else if (window.scrollY < 150 && !visible) setVisible(true);
  }, [visible]);

  React.useEffect(() => {
    checkPosition();

    window.addEventListener('scroll', () => checkPosition());

    return () => window.removeEventListener('scroll', () => checkPosition());
  }, [checkPosition]);

  let tmpList = langList;
  if (staticPage.includes(model)) {
    tmpList = Object.keys(languageConfig)
      .map((lang) => ({
        languageCode: lang,
        slug: '',
      }))
      .filter((lang) => lang.languageCode !== i18n.language)
      .map((langSlug) => {
        const langConfig = languageConfig[`${langSlug.languageCode as LangIndex}`];
        const modelPrefix = langConfig?.slugs?.find((m) => m?.model === model);

        return {
          languageCode: langSlug.languageCode,
          slug: stripTrailingSlash(
            `${langConfig?.siteUrl}${modelPrefix?.slug ?? ''}/${langSlug.slug}`
          ),
        };
      });
  } else if (tmpList.length > 0) {
    tmpList = Object.keys(languageConfig)
      .map((lang) => ({
        languageCode: lang,
        slug: '',
      }))
      .filter((lang) => lang.languageCode !== i18n.language)
      .map((langSlug) => {
        const langConfig = languageConfig[`${langSlug.languageCode as LangIndex}`];
        const modelPrefix = langConfig?.slugs?.find((m) => m?.model === model);
        const slug = tmpList.find((l) => l.languageCode === langSlug.languageCode);

        return {
          languageCode: langSlug.languageCode,
          slug: stripTrailingSlash(
            `${langConfig?.siteUrl}${modelPrefix?.slug ?? ''}/${langSlug.slug}${slug?.slug}`
          ),
        };
      });
  }

  return (
    <>
      <HelmetDatoCms>
        {tmpList.map((langSlug) => (
          <link
            rel="alternate"
            hrefLang={langSlug.languageCode}
            href={langSlug.slug}
            key={langSlug.languageCode}
          />
        ))}
      </HelmetDatoCms>
      {tmpList.map((langSlug) => (
        <a
          key={langSlug.slug}
          href={langSlug.slug}
          className={clsx(styles.langLink, { [styles.langLinkHidden]: !visible })}
        >
          {langSlug.languageCode}
        </a>
      ))}
    </>
  );
};

export default LangSlug;

export const query = graphql`
  fragment LangSlug on DatoCmsLangSlug {
    languageCode
    slug
  }
`;
