import clsx from 'clsx';
import Arrow from 'components/arrow';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Image from 'components/image';
import Link, { LinkType } from 'components/link';
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as styles from './project-card.module.scss';

type ProjectCardType = Pick<
  GatsbyTypes.DatoCmsProject,
  'title' | 'slug' | 'featuredImage' | 'projectCategory' | 'year' | 'client' | 'model'
> & {
  width: 'full' | 'half';
  className?: string;
  imageClassName?: string;
  contentClassName?: string;
};

const ProjectCard: React.FC<ProjectCardType> = ({
  width,
  className,
  imageClassName,
  contentClassName,
  ...project
}) => {
  const { t } = useTranslation();

  let columnConfig: ColumnResponsiveSizes = {};

  if (width === 'half') {
    columnConfig = {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
    };
  }

  return (
    <Column className={clsx(styles.projectCard, className)} {...columnConfig}>
      <Link className={styles.projectCardLink} link={project as LinkType}>
        <Image
          className={clsx(
            styles.projectCardImage,
            (styles as never)[`projectCardImage${width}`],
            imageClassName
          )}
          {...project.featuredImage}
        />
        <div className={clsx(styles.projectCardContent, contentClassName)}>
          <h4>{project.title}</h4>
          {project.projectCategory && (
            <h4>
              <span>{t('Uppdragstyp')}:</span>{' '}
              {project.projectCategory.map((pc) => pc?.title).join(' / ')}
            </h4>
          )}
          {project.client && (
            <h4>
              <span>{t('Kund')}:</span> {project.client}
            </h4>
          )}
          {project.year && (
            <h4>
              <span>{t('År')}:</span> {project.year}
            </h4>
          )}
          <h4 className={styles.projectCardContentReadMore}>
            {t('Läs mer')}
            <Arrow dir={'right'} className={styles.projectCardContentReadMoreArrow} />
          </h4>
        </div>
      </Link>
    </Column>
  );
};

export default ProjectCard;
