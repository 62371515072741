import Link, { LinkType } from 'components/link';
import React from 'react';
import Column from 'components/column';
import LayoutContext from 'layouts/reducer';
import clsx from 'clsx';
import Arrow from 'components/arrow';
import { useLocation } from 'react-use';

import * as styles from './filter.module.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export type FilterItem = {
  id: string;
  title: string;
  link: LinkType;
};

type FilterType = {
  items: FilterItem[];
  activeName?: string;
  pathPrefix: string;
};

const Filter: React.FC<FilterType> = ({ activeName = 'all', items, pathPrefix }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { state } = React.useContext(LayoutContext);
  items = [
    {
      id: 'all',
      title: t('Alla'),
      link: {
        slug: '',
      },
    },
    ...items,
  ];

  return (
    <Column className={styles.filter}>
      <h4 className={styles.filterTitle}>{t('Filtrera')}</h4>
      {!state.isLarge && (
        <h4>
          <button
            className={clsx(styles.filterButton, { [styles.filterButtonOpen]: isOpen })}
            aria-label={t('Öppna filter meny')}
            onClick={() => setIsOpen((o) => !o)}
          >
            {items.find((i) => i.id === activeName)?.title} <Arrow dir={'caretDown'} />
          </button>
        </h4>
      )}
      <div className={clsx(styles.filterContainer, { [styles.filterContainerOpen]: isOpen })}>
        {items
          .map((i) => ({
            ...i,
            link: { slug: `${pathPrefix}${i.link.slug ? '/' + i.link.slug : ''}` },
          }))
          .map((i) => (
            <h4 key={i.id} className={styles.filterItem}>
              <Link
                link={i.link}
                state={{
                  skipScroll: true,
                }}
                activeClassName={styles.filterItemActive}
                className={clsx({
                  [styles.filterItemActive]: new RegExp(`^${i.link.slug}/(\\d+)`).test(
                    pathname ?? ''
                  ),
                })}
              >
                {i.title}
              </Link>
            </h4>
          ))}
      </div>
    </Column>
  );
};

export default Filter;
