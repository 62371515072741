import React from 'react';
import Logo from 'images/svg/logo.inline.svg';
import Image, { ImageProps } from 'components/image';
import Link from 'components/link';
import Section, { SectionProps } from 'components/section';
import clsx from 'clsx';

import * as styles from './hero.module.scss';

type HeroType = Partial<SectionProps<'section'>> & {
  image?: ImageProps;
  className?: string;
};

const Hero: React.FC<HeroType> = ({ image, children, className, ...props }) => {
  return (
    <Section {...props} className={clsx(className, styles.heroSection)}>
      <Link title={'Hem'} link={{ model: { apiKey: 'start_page' } }}>
        <Logo
          className={clsx(styles.heroLogo, {
            [styles.heroLogoWhite]: /white/i.test(props.backgroundColor ?? ''),
          })}
        />
      </Link>
      {image && <Image className={styles.heroImage} {...image} />}
      {children}
    </Section>
  );
};

export default Hero;
