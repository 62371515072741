import Arrow from 'components/arrow';
import Column from 'components/column';
import Link from 'components/link';
import Section from 'components/section';
import React from 'react';

import * as styles from './pagination.module.scss';

type PaginationProps = GatsbyTypes.SitePageContext & {
  pathPrefix: string;
};

const Pagination: React.FC<PaginationProps> = ({
  numberOfPages,
  nextPagePath,
  previousPagePath,
  pathPrefix,
}) => {
  const pages = Array.from(Array(numberOfPages).keys(), (_, i) => i + 1);

  if (pages.length <= 1) return null;

  return (
    <Section className={styles.pagination} backgroundColor={'light'}>
      <Column className={styles.paginationContainer}>
        <Column className={styles.paginationColumnLeft} xl={1} lg={1} md={1} sm={1} xs={1}>
          {previousPagePath && (
            <Link link={{ slug: previousPagePath }}>
              <Arrow dir={'left'} />
            </Link>
          )}
        </Column>
        <Column className={styles.paginationColumnCenter} xl={10} lg={10} md={10} sm={10} xs={10}>
          {pages.map((page) => (
            <Link
              key={`pathPrefix${page}`}
              className={styles.paginationItem}
              activeClassName={styles.paginationItemActive}
              link={{
                slug: `${pathPrefix}${page > 1 ? '/' + page : ''}`,
              }}
            >
              {page}
            </Link>
          ))}
        </Column>
        <Column className={styles.paginationColumnRight} xl={1} lg={1} md={1} sm={1} xs={1}>
          {nextPagePath && (
            <Link link={{ slug: nextPagePath }}>
              <Arrow dir={'right'} />
            </Link>
          )}
        </Column>
      </Column>
    </Section>
  );
};

export default Pagination;
