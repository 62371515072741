import Column from 'components/column';
import Filter from 'components/filter';
import Pagination from 'components/pagination';
import ProjectCard from 'components/project-card';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';
import Hero from 'sections/hero';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import * as styles from './project-archive.module.scss';
import LangSlug from 'components/lang-slug';

type ProjectArchivePageType = {
  pageContext: GatsbyTypes.SitePageContext;
  data: GatsbyTypes.ProjectArchivePageQueryQuery;
};

const ProjectArchivePage: React.FC<ProjectArchivePageType> = ({ data, pageContext }) => {
  const {
    site,
    currentCategory,
    datoCmsProjectArchive,
    allDatoCmsProjectCategory: { projectCategories },
    allDatoCmsProject: { projects },
  } = data;

  const pathPrefix =
    site?.siteMetadata?.slugs?.find((s) => s?.model === 'project_archive')?.slug ?? '';

  return (
    <>
      <HelmetDatoCms
        seo={
          pageContext.categoryId ? currentCategory?.seoMetaTags : datoCmsProjectArchive?.seoMetaTags
        }
      />
      <LangSlug
        langList={currentCategory?.langSlugs as GatsbyTypes.LangSlugFragment[]}
        model={pageContext.categoryId ? 'project_category' : 'project_archive'}
      />
      <Hero className={styles.hero}>
        <Column className={styles.heroContainer}>
          <Column className={styles.heroColumn} xl={4} lg={2} md={4} sm={6} xs={6}>
            <h1>{datoCmsProjectArchive?.title}</h1>
          </Column>
          <Column className={styles.heroColumn} xl={8} lg={10} md={8} sm={6} xs={6}>
            <Filter
              items={projectCategories.map(({ slug, ...pc }) => ({
                id: pc.id as string,
                title: pc.title as string,
                link: {
                  slug: slug as string,
                },
              }))}
              activeName={pageContext.categoryId ? currentCategory?.title : undefined}
              pathPrefix={pathPrefix}
            />
          </Column>
        </Column>
      </Hero>
      <Section
        backgroundColor={'light'}
        topBackgroundColor={'blue'}
        topBackgroundOffset={'80px'}
        className={styles.projectsWrapper}
      >
        <Column className={styles.projectsContainer}>
          {projects.map((p, index, list) => (
            <ProjectCard
              key={p.id}
              width={
                index % 3 === 0 || (index + 1 === list.length && index % 2 === 0) ? 'full' : 'half'
              }
              {...(p as GatsbyTypes.DatoCmsProject)}
            />
          ))}
        </Column>
      </Section>
      <Pagination
        {...pageContext}
        pathPrefix={`${pathPrefix}${pageContext.categoryId ? '/' + currentCategory?.slug : ''}`}
      />
    </>
  );
};

export default ProjectArchivePage;

export const query = graphql`
  query ProjectArchivePageQuery(
    $skip: Int!
    $limit: Int!
    $categoryId: String
    $projectCategoryIds: [String]
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        slugs {
          model
          slug
        }
      }
    }
    datoCmsProjectArchive {
      title
      seoMetaTags {
        tags
      }
    }
    currentCategory: datoCmsProjectCategory(id: { eq: $categoryId }) {
      slug
      title
      langSlugs {
        ...LangSlug
      }
      seoMetaTags {
        tags
      }
    }
    allDatoCmsProject(
      skip: $skip
      limit: $limit
      filter: { projectCategory: { elemMatch: { id: { eq: $categoryId } } } }
      sort: { fields: year, order: DESC }
    ) {
      projects: nodes {
        id
        slug
        title
        year
        client
        model {
          apiKey
        }
        projectCategory {
          id
          title
          slug
        }
        featuredImage {
          ...Image
        }
      }
    }
    allDatoCmsProjectCategory(
      filter: { id: { in: $projectCategoryIds } }
      sort: { fields: title, order: ASC }
    ) {
      projectCategories: nodes {
        slug
        title
        id
      }
    }
  }
`;
